import Image from 'next/image';
import React from 'react';
import styles from './styles.module.scss';

const ThankYouGemsBox = (props) => {
    return (
        <div className={`${styles.thankyougems_box}`}>
            <div className={`${styles.thankyougems_img}`}>
                <Image src="/assets/gemstarlogo.svg" alt="Logo" layout="fill" />
            </div>

            <div className={`${styles.thankyougems_details}`}>
                <p className={`${styles.thankyougemsdetails}`}>
                    {props?.data?.message}
                </p>

                <p className={`${styles.thankyougemstags}`}>
                    Congratulations!!
                </p>

                <div className={`${styles.thankyougems_title_separates}`}>
                    <h2 className={`${styles.thankyougemstitles}`}>
                        {props?.data?.user_gems && props?.data?.user_gems !== ''
                            ? `Current Gems - ${props?.data?.user_gems}`
                            : `Current Gems -100`}
                    </h2>
                    <div className={`${styles.thankyougems_small_img}`}>
                        <Image
                            src="/assets/gemssimple.svg"
                            alt="Logo"
                            layout="fill"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYouGemsBox;
