import React from 'react';
import { Modal } from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import Button from '../../../Components/Button';
import ThankYouGemsBox from '../ThankYouGemsBox';
import * as animationData from "./congratulation.json";
import styles from './styles.module.scss';

const ThankYouModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={`${styles.nomodalborder}`}>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            <h1 className={`${styles.title}`}>NFTON - Terms of Service</h1>
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className={`${styles.thankyou_box}`}>
            <div className={`${styles.nodata_img}`}>
              <Lottie loop animationData={animationData} play />
            </div>
            <div className={`${styles.thankyougem_spacing}`}>
              <ThankYouGemsBox data={props} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={`${styles.nomodalborder} mb-5`}>
          &nbsp;
          <Button varient={'primary'} type="submit" onClick={props.onHide}>
            OK
          </Button>
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ThankYouModal;
